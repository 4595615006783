import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {Link} from "react-router-dom";
import ApiService from "../../../network/ApiService";
import engToNepNumber from "../../../helpers/EngToNepNumber";
import {Lightbox} from "yet-another-react-lightbox";
import {Thumbnails} from "yet-another-react-lightbox/plugins";

function Notices(props) {
    const {organizationId} = useSelector((state) => state.organization);
    const {
        fetchNoticeUpdates,
    } = ApiService();

    const [noticeList, setNewsList] = useState([]);
    const [open, setOpen] = useState(false);
    const [slides, setSlides] = useState([]);
    const [splicedNews, setSplicedNews] = useState([]);
    const languageStatus = sessionStorage.getItem("language");

    const getNewsList = async () => {
        try {
            const response = await fetchNoticeUpdates('notice');
            setNewsList(response.data.aboutUsNewsAndImg);
            setActiveArray(response.data.aboutUsNewsAndImg[0])
        } catch (error) {
            console.log(error.message);
        }
    };

    const [activeArray, setActiveArray] = React.useState(null);
    const getActiveTab = (index) => {
        setActiveArray(noticeList[index])
    }

    const parseHtmlToView = (str) => {
        return {__html: str};
    };
    useEffect(() => {
        if (props.noticeList && props.noticeList.length > 0) {
            setNewsList(props.noticeList);
        }
    }, [props.noticeList]);
    useEffect(() => {
        if (organizationId && !props.noticeList) {
            getNewsList();
        }
    }, [organizationId, props.noticeList]);

    useEffect(() => {
        // if (splicedNews.length === 0) {
        if (props.newsDetails && props.newsDetailId) {
            const newNewsList = [...noticeList];
            const filteredList = newNewsList.filter((news) => {
                return news.newsId !== Number(props.newsDetailId);
            });

            if (filteredList.length > 3) {
                filteredList.length = 3;
            }
            // console.log(shuffle(filteredList),'shuff');
            // const newFilteredList = shuffle(filteredList);
            setSplicedNews(filteredList);
        }
        // }
    }, [noticeList, props.newsDetailId]);

    const handleViewSlides = (galleryImages) => {
        const images = [];
        galleryImages.map((value, item) => {
            images.push({
                src: value,
                alt: "image",
                width: 3840,
                height: 2560,
            });
        });
        setSlides(images);
        setOpen(!open);
        // console.log(images, "images");
    };

    return (
        <>
            {window.location.pathname === '/notices' ?
                <>
            {noticeList ?
                <>
                    <div className="tm-page">
                        <div className="uk-section uk-section-default">
                            <div className="uk-container">
                                <div className="tm-grid-expand">
                                    <h2 className="uk-h2 uk-margin-large uk-text-center">
                                        <span className="underline-secondary">{ languageStatus === 'en' ? `Notices` : `सुचना/गतिविधि` }</span>
                                    </h2>
                                </div>
                                <div className={noticeList.length > 0 ? `uk-grid` : '' }>
                                    <div className="uk-width-1-5@m">
                                        <div
                                            className="uk-tab-left"
                                            uk-tab={`connect: #component-tab-left; animation: uk-animation-fade`}
                                        >
                                            {
                                                noticeList.map((data, index) => {
                                                    return (
                                                        <div key={`link_div_`+index}>
                                                            <a
                                                                href="#"
                                                                key={`link_` + index}
                                                                onClick={() => getActiveTab(index)}
                                                            >{ languageStatus === 'en' ? (data.header ?? data.headerNp) : (data.headerNp ?? data.header) }
                                                            </a>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    </div>
                                    <>
                                        {activeArray ?
                                            <>
                                                <div className="uk-width-expand@m" key={`main_heading_`+activeArray.id}>
                                                    <div id="component-tab-left">
                                                        <div uk-grid="true">
                                                            <div className="uk-width-expand@m">
                                                                <div
                                                                    className="uk-card uk-card-default uk-card-small uk-margin-remove-first-child uk-margin"
                                                                    data-id="page#7-0-1-1"
                                                                >
                                                                    {activeArray.imageUrl !== "n/a" ?
                                                                        <div className="uk-card-media-top">
                                                                            <div className="aspect-square" key={`image_` + activeArray.id}>
                                                                                <img
                                                                                    decoding="async"
                                                                                    src={
                                                                                        activeArray.imageUrl !== "n/a"
                                                                                            ? activeArray.imageUrl
                                                                                            : `assets/images/image.jpg`
                                                                                    }
                                                                                    className="el-image"
                                                                                    alt=""
                                                                                    loading="lazy"
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                        : null}
                                                                    <div className="uk-card-body uk-margin-remove-first-child" key={activeArray.id}>
                                                                        <h3 className="el-title uk-card-title uk-margin-top uk-margin-remove-bottom">
                                                                            { languageStatus === 'en' ? (activeArray.headerEn ?? activeArray.header) : activeArray.header }
                                                                        </h3>
                                                                        <div
                                                                            className="el-content" key={activeArray.id}
                                                                            style={{color: 'black'}}
                                                                            dangerouslySetInnerHTML={parseHtmlToView(
                                                                                (languageStatus === 'en' ? (activeArray.message ?? activeArray.messageNp) : (activeArray.messageNp ?? activeArray.message) )
                                                                                    .split("<p><br></p>")
                                                                                    .join("")
                                                                                /* .substring(0, 200)*/
                                                                            )}
                                                                        >
                                                                        </div>
                                                                    </div>
                                                                    {
                                                                        (activeArray.galleryUrls).length > 0 ?
                                                                            <>
                                                                                <div className="uk-flex uk-flex-column">
                                                                                    <h3 className="uk-text-center">{languageStatus === 'en' ? `Related Images` : 'सम्बन्धित चित्रहरू'}</h3>
                                                                                    <div className="uk-flex uk-flex-center uk-border-rounded">
                                                                                        <Lightbox
                                                                                            open={open}
                                                                                            close={() => setOpen(!open)}
                                                                                            slides={slides}
                                                                                            plugins={[Thumbnails]}
                                                                                            carousel={{finite: true}}
                                                                                        />
                                                                                        {
                                                                                            (activeArray.galleryUrls).length > 0 ?
                                                                                                <>
                                                                                                    <div
                                                                                                        className="uk-card uk-column-span news-gallery-image uk-overflow-hidden uk-margin-small-bottom"
                                                                                                        style={{ cursor: 'pointer', border:'1px solid #9E9E9E', padding:'5px', borderRadius: '5px'}}
                                                                                                        onClick={() => handleViewSlides(activeArray.galleryUrls)}
                                                                                                    >
                                                                                                        <img
                                                                                                            decoding="async"
                                                                                                            className="el-image"
                                                                                                            alt=""
                                                                                                            loading="lazy"
                                                                                                            src={
                                                                                                                activeArray.galleryUrls && activeArray.galleryUrls !== "n/a"
                                                                                                                    ? activeArray.galleryUrls
                                                                                                                    : "assets/images/image.jpg"
                                                                                                            }
                                                                                                        />
                                                                                                    </div>
                                                                                                </> : null
                                                                                        }
                                                                                    </div>
                                                                                </div>
                                                                            </> : null
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                            :
                                            <>
                                                <div style={{border: '1px solid', borderRadius: '5px', padding: '5px'}}>
                                                  <div
                                                      className="uk-flex-wrap-bottom uk-text-bold"
                                                      style={{fontSize: '24px'}}
                                                  >
                                                      { languageStatus === 'en' ? `Notices Not Found` : `सुचना/गतिविधि भेटियन`}...
                                                  </div>
                                                </div>
                                            </>
                                        }
                                    </>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
                : null}
                </> :
                <div className="uk-width-1-2@m uk-first-column">
                    <h2 data-id="page#7-0-0-0" className="uk-margin-large uk-h2">
                        <span
                            className="underline-secondary"> {props.language === 'en' ? `Notices` : `सुचना/गतिविधि`} </span>
                    </h2>
                    <div id="searchList" style={{maxHeight: '500px', overflowY: 'auto'}}>
                        {noticeList && noticeList.length > 0
                            ?
                            // props.newsDetails
                            //     ? splicedNews.map((data, index) => {
                            //         return (
                            //             <div
                            //                 className="uk-card uk-card-default uk-card-small uk-card-body uk-margin-remove-first-child uk-margin"
                            //                 data-id="page#7-0-0-1"
                            //                 key={index}
                            //             >
                            //                 <h3 className="el-title uk-card-title uk-margin-top uk-margin-remove-bottom">२४औँ शिलान्यास सेवाकेन्द्र समुद्घाटन समारोह सम्पन्न</h3>
                            //                 <div className="el-meta uk-text-meta">2023-03-30</div>
                            //             </div>
                            //         );
                            //     })
                            //     :
                            noticeList.map((data, index) => {
                                return (
                                    <div
                                        className="uk-card uk-card-default uk-card-small uk-card-body uk-margin-remove-first-child uk-margin"
                                        data-id="page#7-0-0-2"
                                        key={index}
                                    >
                                        <h3 className="el-title uk-card-title uk-margin-top uk-margin-remove-bottom">
                                            <Link to={`/news-detail/${data.newsId}`}
                                                  style={{color: '#2c3e94', textDecoration: 'none'}}>
                                                {props.language === 'en' ? (data.header ?? data.headerNp) : (data.headerNp ?? data.header)}
                                            </Link>
                                        </h3>
                                        {/*<div dangerouslySetInnerHTML={parseHtmlToView(*/}
                                        {/*    data.message*/}
                                        {/*        .split("<p><br></p>")*/}
                                        {/*        .join("")*/}
                                        {/*        .substring(0, 200)*/}
                                        {/*)}/>*/}
                                        <div className="el-meta uk-text-meta uk-margin-small">
                                            {
                                                props.language === 'en' ? (data.newsDateEn ?? data.newsDate) : engToNepNumber(data.newsDate)
                                            } &nbsp;
                                            {
                                                props.language === 'en' ? (data.newsTime ?? data.newsTime) : (engToNepNumber(data.newsTime.split(' ')[0]) + ' ' + (data.newsTime.split(' ')[1]).replace('AM', 'बिहान').replace('PM', 'बेलुका'))
                                            }
                                        </div>
                                    </div>
                                );
                            })
                            :
                            <div style={{border: '1px solid', borderRadius: '5px', padding: '5px'}}>
                                <div
                                    className="uk-flex-wrap-bottom uk-text-bold"
                                    style={{fontSize: '24px'}}
                                >
                                    {languageStatus === 'en' ? `Notices Not Found` : `सुचना/गतिविधि भेटियन`}...
                                </div>
                            </div>
                        }
                    </div>
                        {
                            noticeList.length > 0 ?
                                <div>
                                    <Link className="uk-link-reset" to="/notices">
                                        <button className="uk-button uk-button-primary">
                                            {props.language === 'en' ? `View All` : `सबै हेर्नुहोस्`}
                                        </button>
                                    </Link>
                                </div>
                                : null
                        }
                    </div>
                    }
                </>
                );
            }

            export default Notices;
