import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

function Newsslides(props) {
    const [newsList, setNewsList] = useState([]);

    useEffect(() => {
        if (props.newsList.length > 0) {
            setNewsList(props.newsList);
        }
    }, [props.newsList]);

    return (
        <>
            <div className="notices uk-padding-small">
                <div className="uk-container">
                    <div className="uk-flex">
                        <div className="notices_tag uk-flex uk-flex-middle">
                            {props.language === "en" ? "Notices" : "सूचनाहरू"}
                        </div>

                        <div className="marquee-container">
                            <div className="marquee-content">
                                <ul className="marquee__content">
                                    {newsList.length > 0 ? (
                                        newsList.map((data, index) => (
                                            <li className="list__item" key={index}>
                                                <Link
                                                    className="uk-link-reset"
                                                    to={`/news-detail/${data.newsId}`}
                                                >
                                                    {props.language === "en"
                                                        ? data.header ?? data.headerNp
                                                        : data.headerNp ?? data.header}
                                                </Link>
                                            </li>
                                        ))
                                    ) : (
                                        <li></li>
                                    )}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Newsslides;
