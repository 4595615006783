import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import NSLogo from  './svgs/logo-ns.jpg';

function Footer(props) {
    const contactList = props.contacts;
    // const phone = contactList.find((item) => item.contactType === "Phone");
    // const email = contactList.find((item) => item.contactType === "Email");
    const orgDetail = props.orgDetails;
    const [phone, setPhone] = useState(null);
    const [email, setEmail] = useState(null);
    const languageStatus = sessionStorage.getItem("language");

    useEffect(() => {
        if (Object.keys(contactList).length > 0) {
            setPhone(contactList.find((item) => item.contactType === "Phone"));
            setEmail(contactList.find((item) => item.contactType === "Email"));
        }
    }, [contactList]);

    // const parseHtmlToView = (str) => {
    //   return { __html: str };
    // };

    return (
        <>
            <div className="mt-3">
                <div
                    id="footer#3"
                    className="uk-position-relative footer uk-section-primary uk-section uk-position-relative"
                >
                    <div className="uk-container uk-container-expand">
                        <div className="uk-margin-large uk-container">
                            <div className="tm-grid-expand uk-grid-column-large uk-grid" uk-grid="true">
                                <div className="uk-width-1-2@s uk-width-1-4@m uk-first-column uk-visible@m">
                                    <div data-id="footer#0-0-0-0" className="uk-margin-remove-vertical">
                                        <Link to="/">
                                            <picture>
                                                <source type="image/webp" sizes="(min-width: 141px) 141px" />
                                                <img
                                                    src={
                                                        orgDetail.bannerImage
                                                            ? orgDetail.bannerImage
                                                            : null
                                                    }
                                                    className="el-image" alt="" loading="lazy"
                                                />
                                            </picture>
                                        </Link>
                                    </div>
                                </div>
                                <div className="uk-width-1-2@s uk-width-1-4@m">
                                    <h1
                                        className="uk-h3 uk-text-secondary uk-margin-small-bottom uk-padding-remove-bottom uk-text-left@m uk-text-center"
                                        data-id="footer#0-0-1-0"
                                    >
                                        <span className="underline-secondary">
                                            {languageStatus === 'en' ? `Link` : `लिङ्क` }
                                        </span>
                                    </h1>
                                    <ul className="uk-list uk-text-left@s uk-text-center" data-id="footer#0-0-1-1">
                                        <li className="el-item">
                                            <div
                                                className="uk-grid-small uk-child-width-expand uk-flex-nowrap uk-flex-middle uk-grid"
                                                uk-grid="true"
                                            >
                                                <div className="uk-width-auto uk-first-column">
                                                    <span className="el-image uk-icon uk-visible@s" uk-icon="icon: chevron-right;"></span>
                                                </div>
                                                <div>
                                                    <div className="el-content uk-panel">
                                                        <Link className="uk-link-reset" to="/about">
                                                            {languageStatus === 'en' ? `Organization Information` : `संस्थाको जानकारी` }
                                                        </Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                        <li className="el-item">
                                            <div
                                                className="uk-grid-small uk-child-width-expand uk-flex-nowrap uk-flex-middle uk-grid"
                                                uk-grid="true"
                                            >
                                                <div className="uk-width-auto uk-first-column">
                                                    <span className="el-image uk-icon uk-visible@s" uk-icon="icon: chevron-right;"></span>
                                                </div>
                                                <div>
                                                    <div className="el-content uk-panel">
                                                        <Link className="uk-link-reset" to="/news">
                                                            {languageStatus === 'en' ? `Notices` : `सुचना/गतिविधि` }
                                                        </Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                        <li className="el-item">
                                            <div
                                                className="uk-grid-small uk-child-width-expand uk-flex-nowrap uk-flex-middle uk-grid"
                                                uk-grid="true"
                                            >
                                                <div className="uk-width-auto uk-first-column">
                                                    <span className="el-image uk-icon uk-visible@s" uk-icon="icon: chevron-right;"></span>
                                                </div>
                                                <div>
                                                    <div className="el-content uk-panel">
                                                        <Link className="uk-link-reset" to="/contact">
                                                            {languageStatus === 'en' ? `Contact` : `सम्पर्क` }
                                                        </Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                                <div className="uk-width-1-2@s uk-width-1-4@m">
                                    <h1
                                        className="uk-h3 uk-text-secondary uk-margin-small-bottom uk-padding-remove-bottom uk-text-left@m uk-text-center"
                                        data-id="footer#0-0-2-0"
                                    >
                                        <span className="underline-secondary">
                                            {languageStatus === 'en' ? `Other Links` : `अतिरित्त लिङ्क` }
                                        </span>
                                    </h1>
                                    <ul className="uk-list uk-text-left@s uk-text-center" data-id="footer#0-0-2-1">
                                        <li className="el-item">
                                            <div
                                                className="uk-grid-small uk-child-width-expand uk-flex-nowrap uk-flex-middle uk-grid"
                                                uk-grid="true"
                                            >
                                                <div className="uk-width-auto uk-first-column">
                                                    <span className="el-image uk-icon uk-visible@s" uk-icon="icon: chevron-right;"></span>
                                                </div>
                                                <div>
                                                    <div className="el-content uk-panel">
                                                        <a className="uk-link-reset" href="http://www.mows.gov.np/" target="_blank">
                                                            {languageStatus === 'en' ? `Ministry of Water Supply` : `खानेपानी मन्त्रालय` }
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                        <li className="el-item">
                                            <div
                                                className="uk-grid-small uk-child-width-expand uk-flex-nowrap uk-flex-middle uk-grid"
                                                uk-grid="true"
                                            >
                                                <div className="uk-width-auto uk-first-column">
                                                    <span className="el-image uk-icon uk-visible@s" uk-icon="icon: chevron-right;"></span>
                                                </div>
                                                <div>
                                                    <div className="el-content uk-panel">
                                                        <a className="uk-link-reset" href="http://www.nwsc.gov.np/" target="_blank">
                                                            {languageStatus === 'en' ? `Department of Water Supply` : `खानेपानी बिभाग` }
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                        <li className="el-item">
                                            <div
                                                className="uk-grid-small uk-child-width-expand uk-flex-nowrap uk-flex-middle uk-grid"
                                                uk-grid="true"
                                            >
                                                <div className="uk-width-auto uk-first-column">
                                                    <span className="el-image uk-icon uk-visible@s" uk-icon="icon: chevron-right;"></span>
                                                </div>
                                                <div>
                                                    <div className="el-content uk-panel">
                                                        <a className="uk-link-reset" href="http://www.tdf.org.np/" target="_blank">
                                                            {languageStatus === 'en' ? `Municipal Development Fund` : `नगर विकास कोष` }
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                                <div className="uk-width-1-2@s uk-width-1-4@m">
                                    <h1
                                        className="uk-h3 uk-text-secondary uk-margin-small-bottom uk-padding-remove-bottom uk-text-left@m uk-text-center"
                                        data-id="footer#0-0-3-0"
                                    >
                                        <span className="underline-secondary">
                                            {languageStatus === 'en' ? `Contact` : `सम्पर्क` }
                                        </span>
                                    </h1>
                                    <div data-id="footer#0-0-3-1">
                                        <ul className="uk-list uk-text-left@s uk-text-center">
                                            <li className="uk-flex uk-flex-top uk-flex-center">
                                                <span
                                                    className="uk-icon uk-margin-small-right"
                                                    uk-icon="icon: location; ratio:1"
                                                    style={{marginTop: '5px'}}
                                                />
                                                <span>
                                                    {orgDetail ? (
                                                        <span className="text">
                                                            {languageStatus === 'en' ? orgDetail.nameEnglish : orgDetail.nameNepali} <br />{" "}
                                                            {languageStatus === 'en' ? orgDetail.addressEnglish :orgDetail.addressNepali}
                                                        </span>
                                                    ) : null}
                                                </span>
                                            </li>
                                            {orgDetail &&
                                            orgDetail.contactNumberNp &&
                                            orgDetail.contactNumberNp.trim() !== "" ? (
                                                <li className="uk-flex uk-flex-top">
                                                    <a className="uk-link-reset" href={`tel:${languageStatus === 'en' ? orgDetail.contactNumberEnglish : orgDetail.contactNumberNp}`}>
                                                        <span
                                                            className="uk-icon uk-margin-small-right"
                                                            uk-icon="icon: receiver; ratio:1"
                                                            style={{marginTop: '5px'}}
                                                        ></span>
                                                        <span className="text">
                                                            {(languageStatus === 'en' ? orgDetail.contactNumber :orgDetail.contactNumberNp)}
                                                        </span>
                                                    </a>
                                                </li>
                                            ) : null}
                                            {email ? (
                                                <li className="uk-flex uk-flex-top uk-flex-center">
                                                    <a className="uk-link-reset" href={`mailto:${email.contactAddress}`}>
                                                        <span
                                                            className="uk-icon uk-margin-small-right"
                                                            uk-icon="icon: mail;ratio:1"
                                                            style={{marginTop: '5px'}}
                                                        ></span>
                                                        <span>{email.contactAddress}</span>
                                                    </a>
                                                </li>
                                            ) : null}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="bottom-bar uk-light">
                    <div className="uk-container">
                        <div uk-grid="true">
                            <div className="uk-width-1-2@s uk-text-left@m uk-text-center">
                                &copy; { languageStatus === 'en' ? `All Rights Reserved ` + orgDetail.nameEnglish : (orgDetail.nameNepali + `| सम्पूर्ण अधिकार सुरक्षित`) }
                            </div>
                            <div className="uk-width-1-2@s uk-text-right@m uk-text-center">
                                <span>{languageStatus === 'en' ? 'Designed and Developed By : ' : `व्यबसाईट निर्माता : ` } </span><a target="_blank" href="https://diyalotech.com/">{languageStatus === 'en' ? `Diyalo Technologies`: ` दियालो टेक्नोलोजी`}</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <style type="text/css">
                { orgDetail?.customCss}
            </style>
        </>
    );
}

export default Footer;
